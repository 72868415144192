<template>
    <div class="ear">
        <el-card>
            <template #header>
                <span>收支管理</span>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="24"   style="text-align:left;">
                            <div class="searchblock">
                                <el-select size="small" v-model="merchantid" filterable style="margin-right:10px;width:150px" clearable placeholder="选择商户" @change="GetStore">
                                    <el-option v-for="item in merchants" :key="item" :label="item.Merchant" :value="item.ID"></el-option>
                                </el-select>
                                <el-select v-model="type" filterable style="margin-right:10px;width:150px" clearable placeholder="请选择类型" size="small">
                                    <el-option label="收入" value="1"></el-option>
                                    <el-option label="支出" value="2"></el-option>
                                </el-select>
                                <span>金额：</span>
                                <el-input-number v-model="start" placeholder="请输入金额" size="small" :controls='false' min="0" style="margin-right:5px;"></el-input-number>
                                <span>至</span>
                                <el-input-number v-model="end" placeholder="请输入金额" size="small" :controls='false' min="0" style="margin-left:5px;margin-right:10px;"></el-input-number>
                                <el-date-picker v-model="range" type="daterange" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts" style="margin-right:10px;" size="small"></el-date-picker>
                                <el-input v-model="keyword"  placeholder="请输入项目名称" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px;margin-right:10px;" size="small"></el-input>
                                <el-button type="primary" size="small" @click="SelectStore" style="margin-right:10px;" v-if="ismerchant != 1">选择店铺</el-button>
                                <el-button type="primary" @click="init" class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="success" @click="Add" size="small">新增收支</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                size="small"
                                >
                    <el-table-column label="商户名称" prop="Merchant"></el-table-column>
                    <el-table-column label="项目" prop="Title"></el-table-column>
                    <el-table-column label="类型">
                        <template #default='scope'>
                            <span v-if="scope.row.Type == 1">收入</span>
                            <span v-if="scope.row.Type == 2">支出</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="店铺" prop="Store"></el-table-column>
                    <el-table-column label="金额(元)" prop="Amount">
                        <template #default='scope'>
                            <span v-if="scope.row.Amount">{{scope.row.Amount/100}}</span>
                            <span v-else>0.00</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="收支日期" prop="AddDTime">
                        <template #default='scope'>
                            <span>{{formatDate(scope.row.AddDTime)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="备注" prop="Remark"></el-table-column>
                    <el-table-column label="操作">
                        <template #default='scope'>
                            <el-button v-if="scope.row.AddMode == 1" @click="Edit(scope.row.ID)" type="text">编辑</el-button>
                        </template>
                    </el-table-column>
                    
                </el-table>
            </div>
        </el-card>
    </div>
    <el-dialog title="编辑收支" v-model="addear" center width="30%">
        <div class="dialog-content">
            <el-form model="earinfo" label-position="right" label-width="120px" size="small" ref="earinfo" :rules="rule">
                <el-form-item label="归属商户：" required v-if="ismerchant != 1">
                    <el-select v-model="earinfo.merchantid" @change="GetStore" :disabled="earinfo.id?true:false">
                        <el-option v-for="item in merchants" :key="item" :value="item.ID" :label="item.Merchant"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目名称：" required>
                    <el-input v-model="earinfo.title"></el-input>
                </el-form-item>
                <el-form-item label="类型：" required>
                    <el-radio-group v-model="earinfo.type" :disabled="earinfo.id?true:false">
                        <el-radio-button :label="1">收入</el-radio-button>
                        <el-radio-button :label="2">支出</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="金额(元)：" required>
                    <el-input-number v-model="earinfo.amount" :controls="false" min="0" :disabled="earinfo.id?true:false"></el-input-number>
                </el-form-item>
                <el-form-item label="日期：" required>
                    <el-date-picker v-model="earinfo.time"></el-date-picker>
                </el-form-item>
                <el-form-item label="备注：" >
                    <el-input type="textarea" v-model="earinfo.remark" :rows="4"></el-input>
                </el-form-item>
                <el-form-item label="选择店铺：" required>
                    <el-select v-model="earinfo.storeid" :disabled='earinfo.merchantid?false:true'>
                        <el-option v-for="item in storelist" :key="item" :value="item.ID" :label="item.Store"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            
        </div>
        <template #footer>
            <el-button type="primary" @click="save" size="small">确定</el-button>
            <el-button  @click="cancel" size="small">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="selstore" title="选择店铺" center>
            <div class="merchant-block">
                <el-transfer v-model="storeid"
                    filterable
                    filter-placeholder="请输入店铺名称"
                    :data='selstorelist'
                    :titles='transfertitle'>
                </el-transfer>
                <div class="bottom">
                    <el-button type="primary" size="small" @click="ConfirmSel">确定</el-button>
                    <el-button size="small" @click="Back">取消</el-button>
                </div>
            </div>
    </el-dialog>
</template>
<style scoped>
.dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
}
.merchant-block {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
<script>
import Cookies from 'js-cookie'
import qs from "qs"
import constant from "@/constant.js"
export default {
    data(){
        return {
            selstore:false,
            storeid:[],
            range:[],
            start:"",
            end:"",
            type:'',
            merchantid:'',
            merchants:[],
            storelist:[],
            keyword:"",
            addear:false,
            tabledata:[],
            load:false,
            totalcount:'',
            curpage:1,
            pagesize:30,
            earinfo:{
                id:'',
                merchantid:'',
                title:'',
                type:'1',
                amount:'',
                time:'',
                storeid:'',
                remark:'',
            },
            rule:{
                merchantid:[{required:true,message:'请选择商户',trigger:'blur'}],
                title:[{required:true,message:'请填写项目名称',trigger:'blur'}],
                
                amount:[{required:true,message:'请填写金额',trigger:'blur'}],
                time:[{required:true,message:'请选择收支日期',trigger:'blur'}],
                storeid:[{required:true,message:'请选择店铺',trigger:'blur'}],
            }
        }
    },
    methods:{
        Edit(id){
            this.axios.get(constant.getear,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.earinfo.id = response.data.ID;
                this.earinfo.merchantid = response.data.MerchantID;
                this.earinfo.storeid = response.data.StoreID;
                this.earinfo.amount = response.data.Amount/100;
                this.earinfo.title = response.data.Title;
                this.earinfo.type = response.data.Type;
                this.earinfo.time = response.data.CreateDTime*1000;
                this.earinfo.remark = response.data.Remark;
                this.addear = true;
            });
        },
        SelectStore(){
            this.selstore = true;
            this.selstorelist = [];
            for(let i = 0;i<this.storelist.length;i++){
                let obj = new Object();
                obj.label = this.storelist[i]['Store'];
                obj.key = this.storelist[i]['ID'];
                this.selstorelist.push(obj);
            }
        },
        GetStore(merchantid){
            this.axios.get(constant.allstore,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    merchantid:merchantid,
                }
            }).then((response)=>{
                console.log(response.data);
                this.storelist = response.data;
            })
        },
        cancel(){
            this.addear = false;
        },
        ConfirmSel(){
            console.log(this.storeid);
            this.selstore = false;
        },
        Back(){
            this.selstore = false;
        },
        Add(){
            this.addear = true;
            this.earinfo = {
                id:'',
                merchantid:'',
                title:'',
                type:'1',
                amount:'',
                time:'',
                storeid:'',
                remark:''
            };
        },
        save(){
            this.$refs['earinfo'].validate((valid)=>{
                if(valid){
                    this.axios.post(constant.saveear,qs.stringify(this.earinfo),{
                        headers:{
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        params:{
                            ismerchant:this.ismerchant,
                            mid:this.mid,
                            role:this.role,
                            opid:this.opid
                        }
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == "OK"){
                            this.$message.success("操作成功");
                            this.$router.go(0);
                        }else{
                            this.$message.error("操作失败");
                        }
                    });
                }else{
                    return false;
                }
            });
        },
        init(){
            this.axios.get(constant.getearlist,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    start:this.start,
                    end:this.end,
                    range:JSON.stringify(this.range),
                    merchantid:this.merchantid,
                    storeid:this.storeid,
                    keyword:this.keyword,
                    type:this.type,
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        },
        formatDate(date){
            var datetime = new Date(date*1000);
            var year = datetime.getFullYear();
            var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            var day = ("0" + datetime.getDate()).slice(-2);
            var hour = ("0" + datetime.getHours()).slice(-2);
            var minute = ("0" + datetime.getMinutes()).slice(-2); 
            var second = ("0" + datetime.getSeconds()).slice(-2); 
            return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        }
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.opid = Cookies.get("id");
        this.role = Cookies.get("role");
        this.axios.get(constant.allstore,{
            headers:{
                "content-type":"application/json"
            },
            params:{
                merchantid:this.merchantid,
                ismerchant:this.ismerchant,
                mid:this.mid,
            }
        }).then((response)=>{
            this.storelist = response.data;
        });
        this.axios.get(constant.allmerchant,{
            headers:{
                "content-type":"application/json"
            }
        }).then((response)=>{
            this.merchants = response.data;
        });
        this.init();
    }
}
</script>